import React from 'react';
import SEO from '../components/seo';
import {redirectToAuthentication} from '../utils/auth';
import LoadingPage from '../components/LoadingPage';

const RedirectToAccount = ({productNr}) => {
  if (typeof window !== 'undefined') {
    window.history.replaceState({}, null, '/');
  }

  setTimeout(()=>{
    redirectToAuthentication(window.location, productNr);
  }, 3000);

  return (
    <>
      <SEO title="Data-api" description="portal data api." lang="en" />
      <div style={{'minHeight': '100vh', 'background': 'linear-gradient(180deg, #017EFD 0%, #0174E9 100%)'}}>
        <LoadingPage LoadingMessage="hello" />
      </div>
    </>
  );
};

export default RedirectToAccount;