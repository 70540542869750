import styled from 'styled-components';

const LoadingContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
  color: #ffffff;
  background: linear-gradient(180deg, #017EFD 0%, #0174E9 100%);
`;

const LoadingText = styled.p`
  max-width: 640px;
  padding: 0 12px;
  box-sizing: border-box;
`;

export { LoadingContainer, LoadingText };
