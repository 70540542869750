import React from 'react';
// import Loader from '../Shared/Loader';
import {LoadingContainer, LoadingText} from './styled';

const LoadingPage = (props) => {
  const {LoadingMessage} = props;
  return (
    <LoadingContainer>
      {/* <Loader /> */}
      {LoadingMessage && (
        <LoadingText className="heading-4">{LoadingMessage}</LoadingText>
      )}
    </LoadingContainer>
  );
};

export default LoadingPage;
